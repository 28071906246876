/* updateOrderInBulk.css */

.admin-user-orders-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  overflow-x: auto; /* Allow horizontal scrolling */
  overflow-y: hidden; /* Disable vertical scrolling */
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.filter-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.filter-options label {
  font-weight: bold;
  margin-right: 10px;
  color: #555;
}

.filter-options input,
.filter-options select {
  padding: 5px 10px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 150px;
}

.select-all-container {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.select-all-container label {
  margin-left: -450px;
}

/* Scrollable Table */
.orders-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  overflow-x: auto; /* Horizontal scrolling for tables */
  display: block;
  white-space: nowrap; /* Prevents table content from wrapping */
}

.orders-table th,
.orders-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}

.orders-table th {
  background-color: #f1f1f1;
  color: #333;
  font-weight: bold;
}

.orders-table td {
  background-color: #f9f9f9;
}

.orders-table tr:nth-child(even) td {
  background-color: #f1f1f1;
}

.orders-table input[type="checkbox"] {
  transform: scale(1.2);
}

.bulk-update-section {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.bulk-update-section select {
  padding: 10px;
  margin-right: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.bulk-update-section button {
  padding: 10px 20px;
  margin-right: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.bulk-update-section button:hover {
  background-color: #218838;
}

.update-order-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.update-order-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

p {
  color: #555;
  font-size: 16px;
  text-align: center;
}

/* Responsive adjustments for mobile */
@media screen and (max-width: 768px) {
  .admin-user-orders-container {
      max-width: 100%; /* Full width for mobile */
      overflow-x: auto; /* Enable horizontal scrolling */
  }

  .filter-options {
      flex-direction: column;
  }

  .filter-options label {
      margin-right: 0;
  }

  .bulk-update-section {
      flex-direction: column;
      align-items: stretch;
  }

  .bulk-update-section button {
      margin-bottom: 10px;
  }

  .orders-table {
      display: block; /* Ensure table scrolls horizontally on small screens */
      overflow-x: auto;
  }
}
.orders-table .selected-row td {
  background-color: rgb(176, 219, 233) !important; /* Ensure that it overrides the existing styles */
}
.progress-bar {
  display: flex;
  height: 20px;
  width: 100%;
  background-color: lightgray;
  border-radius: 3px;
  overflow: hidden;
}

.progress-segment {
  height: 100%;
}

.progress-segment.delivered {
  background-color: rgb(43, 221, 43);
}

.progress-segment.cancelled {
  background-color: rgb(247, 62, 62);
}

.progress-segment.pending {
  background-color: rgb(92, 114, 161);
}

.progress-segment.shipped {
  background-color: rgb(92, 114, 161);
}
