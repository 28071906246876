.manage-user-admin-manage-users-container {
  padding: 20px;
}

.manage-user-admin-manage-users-container h2 {
  margin-bottom: 20px;
}

.manage-user-search-container {
  margin-bottom: 20px;
}

.manage-user-search-container input {
  padding: 5px;
  margin-right: 10px;
}

.manage-user-search-container button {
  padding: 5px 10px;
  cursor: pointer;
}

.manage-user-admin-manage-users-container ul {
  list-style-type: none;
  padding: 0;
}

.manage-user-admin-manage-users-container li {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}

.manage-user-admin-manage-users-container p {
  margin: 5px 0;
}

.manage-user-admin-manage-users-container button {
  margin-left: 10px;
  padding: 5px 10px;
  cursor: pointer;
}

/* Edit Security Question Styles */
.security-question-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.security-question-container select {
  padding: 5px;
  margin-right: 10px;
  width: 200px;
}

.security-question-container input {
  padding: 5px;
  width: 200px;
}

.security-question-container button {
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
}

.save-button, .cancel-button, .edit-button {
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
}

.save-button {
  background-color: #4CAF50;
  color: white;
  border: none;
}

.cancel-button {
  background-color: #f44336;
  color: white;
  border: none;
}

.edit-button {
  background-color: #008CBA;
  color: white;
  border: none;
}

.edit-button:hover, .save-button:hover, .cancel-button:hover {
  opacity: 0.8;
}

/* Orders and Addresses Tables */
.manage-user-orders-table, .manage-user-addresses-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.manage-user-orders-table th, .manage-user-addresses-table th {
  padding: 10px;
  text-align: left;
  
}

.manage-user-orders-table td, .manage-user-addresses-table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.manage-user-toggle-button {
  padding: 5px 10px;
  background-color: #008CBA;
  color: white;
  border: none;
  cursor: pointer;
}

.manage-user-toggle-button:hover {
  opacity: 0.8;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .security-question-container select,
  .security-question-container input {
      width: 100%;
  }

  .manage-user-orders-table, .manage-user-addresses-table {
      width: 100%;
      margin-top: 20px;
  }

  .manage-user-toggle-button {
      width: 100%;
      margin-bottom: 10px;
  }
}
