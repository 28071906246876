.user-profile-container {
    max-width: 60%;
    margin: auto;
    background-color: #fff;
    padding: 20px;
    box-shadow: -1px 2px 8px 10px #544;
    margin-top: 30px;
}

/* Adjust max-width and padding for smaller screens */
@media (max-width: 768px) {
    .user-profile-container {
        max-width: 80%;
        padding: 15px;
    }
}

@media (max-width: 480px) {
    .user-profile-container {
        max-width: 95%;
        padding: 10px;
    }
}

.editButton, .addButton, .subButton {
    padding: 6px 15px;
    font-size: 1rem;
    background-color: #070F2B;
    color: #fff;
    border: none;
    cursor: pointer;
    margin-top: 10px;
    border-radius: 4px;
    transition: transform 0.3s ease, color 0.3s ease;
    filter: drop-shadow(0px 0px 3px rgba(0,0,0,0.5)); /* Add shadow to enhance boldness */

}

.addButton:hover, .editButton:hover, .subButton:hover{

  transform: scale(1.1); /* Increase size on hover */
  filter: drop-shadow(0px 0px 6px rgba(0,0,0,0.7)); /* Increase shadow on hover */
  
}

/* Adjust padding and font-size for smaller screens */
@media (max-width: 768px) {
    .editButton, .addButton, .subButton {
        padding: 5px 8px;
        font-size: 0.8rem;
    }
}

@media (max-width: 480px) {
    .editButton, .addButton, .subButton {
        padding: 4px 6px;
        font-size: 0.7rem;
    }
}
