/* Prevent horizontal scrolling */
body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.footer {
    background-color: #070F2B;
    padding: 20px 0;
    border-top: 1px solid #e5e5e5;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    gap: 20px;
    flex-wrap: nowrap; /* Ensure items stay in a single line on full screen */
    overflow-x: hidden; /* Ensure no horizontal overflow */
}

.footer-item {
    text-align: center;
    flex: 1;
    min-width: 150px; /* Ensure items have a minimum width */
}

.footer-item img {
    width: 70px;
    height: 70px;
    margin: 0 10px; /* Add margin to both sides of the image */
    vertical-align: middle; /*Align images in the middle of the text*/
}

.footer-item p {
    font-size: 1.2rem;
    color: #beb0b0;
    font-family: 'Poppins', sans-serif;
}

.Policy {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    flex-wrap: wrap; /* Allow the policy links to wrap on smaller screens */
}

.Policy ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 60px;
    flex-wrap: wrap;
    justify-content: center; /* Center the links on smaller screens */
}

.Policy li {
    margin: 0 15px;
    position: relative;
    font-family: 'Poppins', sans-serif;
    font-size: 1.3rem;
    color: rgb(245, 197, 6);
}

.Policy a {
    text-decoration: none;
    color: #beb0b0;
    font-size: 1.5rem;
    font-family: 'Poppins', sans-serif;
}

.Policy li::after {
    content: '';
    display: block;
    height: 2px;
    background-color: #beb0b0;
    position: absolute;
    bottom: -3px;
    left: 0;
    right: 0;
    transform: scaleX(0);
    transition: transform 0.3s ease;
    transform-origin: left;
}

.Policy li:hover::after {
    transform: scaleX(1);
    transform-origin: right;
}

address p, .contacts p {
    font-size: 1rem;
    color: rgb(191, 220, 220);
}

.footer-section {
    flex: 1;
    margin: 0 20px;
    min-width: 250px; /* Ensure sections have a minimum width */
}

.logoWfFooter {
    width: 100px;
    margin-bottom: 20px;
}

.social-icons-footer {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    flex-wrap: wrap; /* Allow icons to wrap on smaller screens */
}

.social-icons-footer img {
    width: 50px;
    height: 50px;
    margin: 0 10px;
}

.social-icons-footer img:hover {
    transform: scale(1.3) translateZ(0);
}

address, .contacts {
    font-size: 0.9rem;
    color: #333;
    line-height: 1.6;
    text-align: center; /* Center the text for better appearance on small screens */
}

.contact-form {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: 0 auto; /* Center the form horizontally */
}

.contact-form label {
    margin-bottom: 10px;
    font-size: 1rem;
    color: #beb0b0;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.contact-form .sendBtn {
    background-color: #055aff;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    width: 90px;
    align-self: center; /* Ensure the button is centered */
}

.contact-form .sendBtn:hover {
    background-color: #563d7c;
}
.promo-text h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: white;
}
.promo-text p {
    font-size: 1rem;
    color: white;
    padding-bottom: 30px;
}

.footer-bottom {
    text-align: center;
    padding: 10px;
    background-color: #070F2B; /* Adjust as needed */
    color: #fff;
    font-size: 14px;
}

@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
        gap: 10px; /* Adjust gap on smaller screens */
    }

    .footer-item {
        margin-bottom: 20px;
    }

    .footer-item img {
        margin: 0;
    }

    .footer-section {
        margin: 20px 0;
    }

    .social-icons-footer {
        justify-content: center;
    }

    .contact-form .sendBtn {
        margin-left: 0;
        align-self: center;
    }
}
