/* allreviews.css */

.review-container {
    border: 1px solid #ddd;
    padding: 20px;
    margin: 20px auto; /* Center the container */
    background-color: #f9f9f9;
    border-radius: 8px;
    width: 80%; /* Reduce the width of the container */
    max-width: 800px; /* Set a maximum width for larger screens */
    box-sizing: border-box; /* Ensure padding doesn't affect width */
}

.reviewImg {
    width: 30%;
    height: auto; /* Maintain aspect ratio */
    object-fit: cover;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20px;
    transition: transform 0.3s ease, width 0.3s ease, height 0.3s ease;
    cursor: pointer; /* Indicate clickable image */
}

.reviewImg.enlarged {
    width: 80%; /* Expand the image on click */
    height: auto; /* Maintain aspect ratio */
    transform: none; /* Reset any hover effects */
}

.review-tag {
    display: inline-block;
    padding: 4px 8px;
    background-color: #007bff;
    color: white;
    border-radius: 12px;
    margin-right: 10px;
    font-size: 12px;
}

.review-date {
    font-size: 1.2rem;
    color: #0f0e0e;
}

.review-rating {
    font-weight: bold;
    color: #ff9900;
    font-size: 2rem;
}

.star {
    font-size: 1.5rem;
    color: #ccc; /* Light gray for empty stars */
    margin-right: 2px;
}

.star.filled {
    color: #c67f16; /* Orange for filled stars */
}

.review-rating h{
    color: #0f0e0e;
}

.review-comment {
    margin: 10px 0;
    font-size: 1.7rem;
    font-weight: bold;
    color: #000;
}

.reviewBtn {
    padding: 10px 20px;
    background-color: #070F2B;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    width: 100%; /* Ensure button is responsive */
    max-width: 200px; /* Set a maximum width */
    margin-top: 10px; /* Add margin for spacing */
    box-sizing: border-box; /* Ensure padding doesn't affect width */
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.reviewBtn:hover {
    background-color: #59169b;
}

/* Media query for responsiveness */
@media (max-width: 600px) {
    .review-container {
        width: 95%;
        padding: 10px;
    }

    .reviewImg {
        width: 40%;
        margin-left: 0; /* Center the image */
    }

    .review-rating, .review-comment, .review-date {
        font-size: 1.2rem;
    }

    .reviewBtn {
        width: 70%;
        max-width: 70%; /* Remove max-width restriction */
        border-radius: 4px;
    }
}
