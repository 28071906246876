/* ForgetPasswordReset.css */
.forget-password-reset-container {
  width: 100%;
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.forget-password-reset-form-group {
  margin-bottom: 20px;
}

.forget-password-reset-input {
  width: 100%; /* Default to full width */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

.forget-password-reset-icon {
  margin-right: 10px;
  color: #333;
}

.forget-password-reset-button {
  width: 50%;
  padding: 10px 5px;
  background-color: #070F2B;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  display: block;
  margin: 0 auto; /* Center the button horizontally */
}

.forget-password-reset-button:hover {
  background-color: #59169b;
}

.forget-password-reset-error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

.forget-password-reset-success-message {
  color: green;
  font-size: 14px;
  margin-top: 10px;
}

.forget-password-reset-container h2 {
  text-align: center;
}

/* Contact Us Link */
.forget-password-contact-link {
  display: block;
  margin: 20px auto 0;
  text-align: center;
  color: #070F2B;
  font-weight: bold;
  text-decoration: none;
  padding: 10px 0;
  border-top: 1px solid #ddd;
}

.forget-password-contact-link:hover {
  color: #59169b;
  text-decoration: underline;
}

.forget-password-contact-us-info{
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
  color: #333;
}

/* Media Queries for Further Reduced Width */
@media (max-width: 1024px) {
  .forget-password-reset-container {
    width: 75%; /* Reduced width for larger screens */
    margin: 40px auto;
    padding: 20px;
  }

  .forget-password-reset-input {
    width: 90%; /* Reduce input width slightly */
    padding: 8px;
  }
  .forget-password-contact-us-info{
    text-align: center;
    font-size: 12px;
    margin-top: 10px;
    color: #333;
  }
}

@media (max-width: 768px) {
  .forget-password-reset-container {
    width: 70%; /* More reduction for tablets */
    padding: 15px;
    margin: 40px auto;
  }

  .forget-password-reset-input {
    width: 95%; /* Narrower input field */
    padding: 8px;
    font-size: 13px;
  }

  .forget-password-reset-button {
    padding: 10px;
    font-size: 15px;
  }
  .forget-password-contact-us-info{
    text-align: center;
    font-size: 12px;
    margin-top: 10px;
    color: #333;
  }
}

@media (max-width: 480px) {
  .forget-password-reset-container {
    width: 85%; /* Reduced to 85% for mobile */
    padding: 10px;
    margin: 60px auto;
  }

  .forget-password-reset-input {
    width: 90%; /* Further reduce input field width */
    padding: 6px;
    font-size: 12px;
  }

  .forget-password-reset-button {
    padding: 8px;
    font-size: 14px;
  }
  .forget-password-contact-us-info{
    text-align: center;
    font-size: 12px;
    margin-top: 10px;
    color: #333;
  }
}

@media (max-width: 360px) {
  .forget-password-reset-container {
    width: 80%; /* Further reduce for very small screens */
    padding: 8px;
    margin: 60px auto;
  }

  .forget-password-reset-input {
    width: 95%; /* Even narrower input field for very small screens */
    padding: 6px;
    font-size: 12px;
  }

  .forget-password-reset-button {
    padding: 8px;
    font-size: 14px;
  }
  .forget-password-contact-us-info{
    text-align: center;
    font-size: 12px;
    margin-top: 10px;
    color: #333;
  }
}
