.adminNav {
    width: 250px;
    border-right: 1px solid #ddd;
    background-color: #0d1533;
    padding: 20px; /* Added padding for spacing */
    margin-top: 0; /* Adjusted to fix alignment */
    min-height: 100vh; /* Make sure navbar occupies full height of the viewport */
  }
  
  .adminNav ul {
    list-style: none;
    padding: 0; /* Remove default padding */
    margin: 0; /* Remove default margin */
  }
  
  .adminNav ul li {
    margin-bottom: 15px; /* Add space between list items */
  }
  
  .adminNav ul li a {
    text-decoration: none;
    color: #ffffff; /* White font color */
    font-size: 19px; /* Adjusted font size */
    display: block;
    padding: 10px 15px; /* Add padding inside links for better spacing */
    border-radius: 4px; /* Rounded corners */
    transition: background-color 0.3s ease; /* Smooth hover effect */
  }
  
  .adminNav ul li a:hover {
    background-color: #1a233f; /* Darker background on hover */
  }
  
  .adminNav ul li button {
    text-decoration: none;
    color: #ffffff;
    background-color: #549df0;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .adminNav ul li button:hover {
    background-color: #77afd6; /* Slightly darker on hover */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .adminNav {
      width: 100%;
      border-right: none; /* Remove border on smaller screens */
      border-bottom: 1px solid #ddd;
    }
  
    .adminNav ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center; /* Center items on smaller screens */
    }
  
    .adminNav ul li {
      width: 100%; /* Make each link full width */
      text-align: center; /* Center-align text */
    }
  
    .adminNav ul li a, .adminNav ul li button {
      width: 100%; /* Full-width for buttons and links */
    }
  }
  