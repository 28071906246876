.user-review-container {
    padding: 20px;
  }
  
  .review-item {
    border: 1px solid #ddd;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .review-image-container {
    max-width: 150px; /* Small image size */
    cursor: pointer;
  }
  
  .review-image {
    width: 100%;
    height: auto;
    border-radius: 5px;
    transition: transform 0.2s ease;
  }
  
  .review-image:hover {
    transform: scale(1.1); /* Slight zoom on hover */
  }
  
  .delete-button {
    background-color: #d9534f;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .delete-button:hover {
    background-color: #c9302c;
  }
  
  @media (max-width: 768px) {
    .review-item {
      padding: 15px;
    }
  
    .delete-button {
      width: 100%; /* Full width on smaller screens */
      margin-top: 10px;
    }
  }
  
  /* Modal for enlarged image */
  .image-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.9); /* Dark background */
  }
  
  .close-modal {
    position: absolute;
    top: 10px;
    right: 25px;
    color: #fff;
    font-size: 35px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .modal-content {
    max-width: 80%;
    max-height: 80%;
    border-radius: 10px;
    animation: zoom 0.6s;
  }
  
  @keyframes zoom {
    from {transform: scale(0.1)} 
    to {transform: scale(1)}
  }
  