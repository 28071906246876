/* UploadProduct.css */

.formUpload {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  
  .inputProduct,
  textArea {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  textArea {
    height: 100px;
  }
  /*
  input[type="file"] {
    margin-top: 5px;
  }
  */
  .uploadButton {
    background-color: rgb(59, 88, 88);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    font-size: 1rem;
  }
  .uploadButton1 {
    background-color: rgb(59, 88, 88);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    margin-left: 20px;
    font-size: 1rem;
  }
  
  .uploadButton1:hover{
    background-color: #767e81;

  }
  .uploadButton:hover {
    background-color: #767e81;
  }
  .divColor {
    margin-bottom: 10px;
  }
  
  
 .divColor div > .inputProduct {
    width: calc(50% - 10px);
    margin-right: 10px;
  }
  /*
  .divColor > input[type="file"] {
    width: calc(50% - 10px);
  }
  */
  .divColor > .uploadButton {
    margin-top: 5px;
  }

  .colorSelect{

    font-size: 1rem;
    padding: 7px;
    background-color: rgb(182, 194, 194);
  }
.remColor{

  background-color: rgb(182, 194, 194);
  font-size: 1rem;
  padding: 4px 5px;
  border-radius: 3px;
}
  