/* Main container for orders */
.order-container.myOrders {
  max-width: 60%;
  margin: auto;
  background-color: #fff;
  padding: 20px;
  box-shadow: -1px 2px 8px 10px #544;
  margin-top: 30px;
}

@media (max-width: 1024px) {
  .order-container.myOrders {
    max-width: 70%; /* Slightly wider for medium screens */
    padding: 18px;
  }
}

@media (max-width: 768px) {
  .order-container.myOrders {
    max-width: 80%;
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .order-container.myOrders {
    max-width: 95%;
    padding: 10px;
  }
}

/* Header styling */
.order-container.myOrders h4.myOrders {
  text-align: center;
  font-size: 2.5rem;
  margin-left: -10px;
}

@media (max-width: 1024px) {
  .order-container.myOrders h4.myOrders {
    font-size: 2.2rem;
    margin-left: -5px;
  }
}

@media (max-width: 768px) {
  .order-container.myOrders h4.myOrders {
    font-size: 2rem;
    margin-left: 0;
  }
}

@media (max-width: 480px) {
  .order-container.myOrders h4.myOrders {
    font-size: 1.5rem;
    margin-left: 0;
  }
}

/* Filter options styling */
.filter-options.myOrders {
  margin-bottom: 20px;
}

.filter-options.myOrders select.myOrders {
  margin-left: 10px;
  width: 100%; /* Full width on small screens */
}

@media (max-width: 768px) {
  .filter-options.myOrders {
    text-align: center; /* Center align filter options */
  }
}

/* Order item header styling */
.order-container.myOrders h3.myOrders {
  cursor: pointer;
}

/* Product image styling */
.product-image.myOrders {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

@media (max-width: 480px) {
  .product-image.myOrders {
    width: 80px; /* Slightly smaller image size on small screens */
    height: 80px;
  }
}

/* General button styling */
.myOrders button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  margin-top: 10px;
  background-color: #070F2B;
  color: #fff;
  transition: transform 0.3s ease, color 0.3s ease;
  filter: drop-shadow(0px 0px 3px rgba(0,0,0,0.5)); /* Add shadow to enhance boldness */
  
}

.myOrders button:hover{

  transform: scale(1.1); /* Increase size on hover */
  filter: drop-shadow(0px 0px 6px rgba(0,0,0,0.7)); /* Increase shadow on hover */
  
}

/* Track Order Button */
.trackButton.myOrders {
  background-color: #070F2B;
  color: #fff;
  
}

.trackButton.myOrders:hover {
  transform: scale(1.1); /* Increase size on hover */
  filter: drop-shadow(0px 0px 6px rgba(0,0,0,0.7)); /* Increase shadow on hover */
  
}

/* Return Order Button */
.returnButton.myOrders {
  background-color: #070F2B;
  color: #fff;
  margin-left: 10px;
}

.returnButton.myOrders:hover {
  transform: scale(1.1); /* Increase size on hover */
  filter: drop-shadow(0px 0px 6px rgba(0,0,0,0.7)); /* Increase shadow on hover */
  
}

/* Cancel Order Button */
.cancelButton.myOrders {
  background-color: #070F2B;
  color: #fff;
  margin-left: 10px;
}

.cancelButton.myOrders:hover {
  transform: scale(1.1); /* Increase size on hover */
  filter: drop-shadow(0px 0px 6px rgba(0,0,0,0.7)); /* Increase shadow on hover */
  
}

/* Responsive button styling */
@media (max-width: 768px) {
  .myOrders button {
    font-size: 12px;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .myOrders button {
    font-size: 10px;
    padding: 6px 12px;
  }
}

/* Tracking container styling */
.tracking-container.myOrders {
  margin-top: 20px;
}

/* Timeline styling */
.timeline.myOrders {
  position: relative;
  margin-top: 20px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.timeline.myOrders:before {
  content: '';
  position: absolute;
  top: 0;
  left: 15px;
  width: 2px;
  height: 100%;
  background: #ddd;
}

.timeline-item.myOrders {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 40px;
  margin-bottom: 20px;
}

.timeline-item.myOrders:last-child {
  margin-bottom: 0;
}

.timeline-icon.myOrders {
  width: 20px;
  height: 20px;
  background-color: #4CAF50;
  border-radius: 50%;
  margin-right: 10px;
}

.timeline-content.myOrders {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.timeline-status.myOrders {
  font-weight: bold;
  margin-bottom: 5px;
}

.timeline-location.myOrders {
  color: #666;
}

.timeline-content.myOrders p {
  margin: 0;
}

.timeline-date.myOrders {
  color: gray;
}

/* Different color for delivered timeline icon */
.delivered .timeline-icon.myOrders {
  background-color: #2196F3;
}

/* Button group styling */
.button-group.myOrders {
  display: flex;
  gap: 10px;
  flex-wrap: wrap; /* Allow buttons to wrap on small screens */
}

@media (max-width: 480px) {
  .button-group.myOrders {
    flex-direction: column; /* Stack buttons vertically on very small screens */
    gap: 5px;
  }
}

/* Order Item Styling */
.order-item.myOrders {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
}

/* Product Image Styling */
.product-image-container.myOrders {
  margin-top: 10px;
}

.product-image.myOrders {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

/* Review Section Styling */
.review-section.myOrders {
  margin-top: 15px;
}
