/* ThankYouPage.css */

/* Base styles */
.thank-you-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(to bottom, #e0f7fa, #ffffff);
    animation: fadeIn 1s ease-in-out;
}

.thank-you-content {
    text-align: center;
    background: rgba(255, 255, 255, 0.95);
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    width: 60%;
    max-width: 600px;
    transform: scale(0.8);
    animation: popIn 0.5s ease-in-out forwards;
}

/* Icon styles */
.icon-container {
    font-size: 50px;
    color: #4CAF50;
    margin-bottom: 20px;
    opacity: 0;
    animation: slideDown 1s ease-out forwards 0.5s;
}

.check-icon {
    font-size: 60px;
    font-weight: bold;
    color: #38bb54;
    background-color: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 100%;
    width: 100px;
    height: 100px;
    border: 6px solid #38bb54;
}

/* Text styles */
h1 {
    font-size: 28px;
    color: #ff7eb3;
    margin-bottom: 10px;
}

p {
    font-size: 18px;
    color: #555;
    margin-bottom: 20px;
}

/* Order summary section */
.order-summary {
    margin-top: 20px;
    opacity: 0;
    animation: fadeInUp 1s ease-out forwards 1s;
}

.order-summary h2 {
    font-size: 22px;
    margin-bottom: 10px;
    color: #222;
}

.order-summary table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.order-summary th, .order-summary td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: center;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

/* Table header styling */
.order-summary th {
    background-color: #f7f7f7;
    font-weight: bold;
    color: #333;
}

/* Striped row effect */
.order-summary tr:nth-child(even) {
    background-color: #f9f9f9;
}

/* Hover effect for rows */
.order-summary tr:hover {
    background-color: #f1f1f1;
    cursor: pointer;
}

/* Text styling for table cells */
.order-summary td {
    color: #555;
}

.order-summary td:first-child {
    font-weight: bold;
}

/* Button styles */
.button-group {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.primary-button, .secondary-button {
    padding: 12px 25px;
    border: none;
    cursor: pointer;
    border-radius: 25px;
    font-size: 18px;
    transition: all 0.3s ease-in-out;
}

.primary-button {
    background: #ff7eb3;
    color: white;
    box-shadow: 0 4px 10px rgba(255, 126, 179, 0.5);
}

.secondary-button {
    background: #28a745;
    color: white;
    box-shadow: 0 4px 10px rgba(40, 167, 69, 0.5);
}

.primary-button:hover {
    background: #ff5a9e;
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(255, 90, 158, 0.6);
}

.secondary-button:hover {
    background: #218838;
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(40, 167, 69, 0.6);
}

/* Animations */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes popIn {
    from { transform: scale(0.8); opacity: 0; }
    to { transform: scale(1); opacity: 1; }
}

@keyframes slideDown {
    from { transform: translateY(-30px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}

@keyframes fadeInUp {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}

/* Media Query for mobile responsiveness */

@media (max-width: 768px) {
    .thank-you-content {
        width: 90%;
        transform: scale(1);
        padding: 20px;
    }

    h1 {
        font-size: 24px;
    }

    p {
        font-size: 16px;
    }

    .order-summary {
        margin-top: 10px;
    }

    /* Improved table for mobile */
    .order-summary table {
        width: 100%;
        display: block;
        overflow-x: auto;
        margin-top: 20px;
        border-radius: 10px;
    }

    .order-summary th, .order-summary td {
        font-size: 14px;
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    /* Ensure table rows have proper padding and width */
    .order-summary th, .order-summary td {
        word-wrap: break-word;
        text-align: center;
    }

    .button-group {
        flex-direction: column;
        gap: 15px;
    }

    .primary-button, .secondary-button {
        width: 100%;
        padding: 12px;
    }
}
@media (max-width: 478px) {
     .order-summary th, .order-summary td {
        font-size: 14px;
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}