.page-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 100vh;
    background-color: #f0f2f5;
    margin: 0;
    padding-top: 10vh; /* Adjust this value to set the distance from the top */
}

.containerPass {
    background-color: #fff;
    padding: 5vh 5vw; /* Adjust padding for smaller screens */
    border-radius: 10px;
    box-shadow: -1px 2px 8px 10px #544;
    width: 80%;
    max-width: 400px; /* Set a max-width for larger screens */
    text-align: center;
    box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
}

.containerPass h3 {
    font-size: 1.8rem; /* Adjust font size for smaller screens */
    font-family: 'Poppins';
    white-space: nowrap; /* Prevent line breaks */
   
}

.form-group-pass {
    margin-bottom: 1.5rem;
    text-align: left;
}

.label-pass {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1rem;
}

.input-pass {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.button-pass {
    width: 100%;
    padding: 0.75rem;
    background-color: #070F2B;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.button-pass:hover {
    transform: scale(1.1); /* Increase size on hover */
  filter: drop-shadow(0px 0px 6px rgba(0,0,0,0.7)); /* Increase shadow on hover */
  
}

.forgot-password {
    margin-top: 1.5rem;
    font-size: 0.875rem;
}

.forgot-password a {
    color: #007bff;
    text-decoration: none;
}

.forgot-password a:hover {
    text-decoration: underline;
}

/* Media queries for responsiveness */
@media (min-width: 600px) {
    .containerPass {
        padding: 10vh 5vw;
    }
    
    

    .form-group-pass {
        margin-bottom: 2rem;
    }

    .label-pass {
        font-size: 1.125rem;
    }

    .input-pass {
        padding: 0.75rem;
        font-size: 1.125rem;
    }

    .button-pass {
        padding: 1rem;
        font-size: 1.125rem;
    }

    .forgot-password {
        font-size: 1rem;
    }
}
