.related-products {
  padding: 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.related-products h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.products-list-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.navigation-button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 18px;
  border-radius: 5px;
  transition: background-color 0.3s;
  z-index: 2;
}

.navigation-button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

.navigation-button:not(:disabled):hover {
  background-color: #555;
}

.products-list-container {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.products-list {
  display: flex;
  gap: 15px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.product-item {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  width: 200px;
  transition: transform 0.3s ease;
}

.product-item:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.product-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
}

.product-name {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.product-price {
  font-size: 14px;
  color: #ff6600;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  body{
    width:100%;
    overflow-x:hidden;
    }
  .related-products {
    padding: 10px;
    overflow: hidden;
  }

  .products-list {
    display: grid; /* Use grid for layout */
    grid-template-columns: repeat(3, 1fr); /* Arrange 4 products per row */
    gap: 10px;
    box-sizing: border-box; /* Include padding and border in width calculations */
  }

  .product-item {
    width: calc(100% - 10px);/* Let grid control the size */
    min-width: 85px; /* Ensure it doesn't get too small */
    max-width: 100%; /* Ensure it takes up the available space */
    box-sizing: border-box; /* Include padding and border in width calculations */
  }
  .product-name {
    font-size: 15px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
  }

  .product-price {
    font-size: 14px;
    color: #ff6600;
  }
  .navigation-button {
    background-color: #333;
    color: #fff;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 18px;
    border-radius: 50px;
    transition: background-color 0.3s;
    z-index: 2;
  }
  
  .navigation-button:disabled {
    background-color: #aaa;
    cursor: not-allowed;
  }
  
  .navigation-button:not(:disabled):hover {
    background-color: #555;
  }
}

