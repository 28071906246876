.admin-page {
  padding: 40px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin-page h2 {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.form-container {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 450px;
  margin-bottom: 40px;
}

.form-container h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

input,
textarea {
  padding: 12px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  transition: border-color 0.3s;
  width: 100%;
  box-sizing: border-box; 
}

input:focus,
textarea:focus {
  border-color: #007bff;
  outline: none;
}

textarea {
  resize: vertical;
  min-height: 100px;
}

.block-pincode-btn {
  padding: 12px;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.block-pincode-btn:disabled {
  background-color: #a9a9a9;
}

.block-pincode-btn:hover:not(:disabled) {
  background-color: #0056b3;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 30px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

th,
td {
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
  font-size: 1rem;
}

th {
  background-color: #f4f4f4;
  color: #333;
}

tbody tr:hover {
  background-color: #f1f1f1;
}

tbody tr {
  transition: background-color 0.3s;
}

table .no-data {
  text-align: center;
  font-style: italic;
  color: #888;
}
