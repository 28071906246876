.returned-orders-container {
    max-width: 100%;
    padding: 20px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .table-wrapper {
    overflow-x: auto; /* Makes the table scrollable on smaller screens */
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
    font-size: 16px;
  }
  
  thead {
    background-color: #f2f2f2;
  }

  .table-wrapper td{
    background-color: #f2f2f2;
  }
  
  th, td {
    padding: 12px 15px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  ul {
    padding-left: 20px;
  }
  
  li {
    list-style: none;
  }
  
  @media (max-width: 768px) {
    h2 {
      font-size: 20px;
    }
  
    th, td {
      padding: 8px 10px;
      font-size: 14px;
    }
  
    ul {
      padding-left: 15px;
    }
  }
  
  @media (max-width: 480px) {
    th, td {
      font-size: 12px;
      padding: 6px 8px;
    }
  
    ul {
      padding-left: 10px;
    }
  }
  