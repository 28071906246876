/* shop.css */
.product-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center cards when they are fewer in number */
}

.product {
  position: relative; /* Ensures positioning for children elements if needed */
  flex: 1 1 calc(25% - 20px); /* Responsive flex properties */
  box-sizing: border-box; /* Ensures padding and border are included in total width/height */
  border: 1px solid #f7f3f3; /* Border styling */
  padding: 16px; /* Padding inside the product element */
  margin: 10px; /* Margin around the product element */
  text-align: center; /* Centers the text content */
  background-color: #ffffff; /* Optional: background color for contrast */
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out; /* Smooth transitions for opacity and transform */
  opacity: 0; /* Start hidden */
  will-change: opacity, transform; /* Optimizes rendering for animations */
  max-width: 350px;
}

/* Animation Keyframes */
@keyframes slideIn {
  from {
    transform: translateY(30%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Animation Class */
.product.animate-on-scroll {
  animation: slideIn 0.6s forwards; /* Adjust duration as needed */
}

.sales-tag {
  position: absolute;
  top: 10px;
  right: 10px;
}

.sales-tag-image {
  width: 75px;
  height: 75px;
}

.sales-tag-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: bold;
  font-size: 1.2em;
  text-align: center;
  line-height: 1;
}

.product-image {
  max-width: 100%;
  height: auto;
}

.product-image:hover {
  overflow: hidden;
  transform: scale(1.2);
  transition-duration: 0.4s;
}

.product-title {
  font-size: 1.5em;
  margin: 0.5em 0;
}

.listing-price {
  text-decoration: line-through;
  color: #888;
}

.product-selling-price {
  font-size: 1.5em;
  color: #e91e63;
  margin-top: 5px;
  font-weight: bold;
}

.percentage-difference {
  font-size: 0.9em;
  color: #4caf50;
}

.product-color {
  margin-top: 5px;
}

/* Add to Cart Button */
.add-to-cart-btn {
  background-color: #FFD700; /* Bright yellow for the Add to Cart button */
  color: black;
  padding: 10px 15px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease; /* Smooth transition for button */
}

.add-to-cart-btn:hover{
  filter: drop-shadow(0px 0px 6px rgba(230, 230, 4, 0.7)); /* Increase shadow on hover */
  transform: scale(1.1);
  
}

.add-to-cart-btn:active {
  transform: scale(0.86); /* Slight scaling inward on click */
}

/* Buy Now Button */
.buy-now-btn {
  background-color: #FF9800; /* Orange for the Buy Now button */
  color: black;
  padding: 10px 15px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease; /* Smooth transition for button */
}

.buy-now-btn:hover,
.buy-now-btn:focus {
  background-color: #FFC107; /* Slightly lighter orange on hover/focus */
}

.buy-now-btn:active {
  transform: scale(0.86); /* Slight scaling inward on click */
}

.filter-navbar {
  display: flex;
  justify-content: space-around;
  background-color: white;
  color: black;
  padding: 10px;
  margin-bottom: 20px;
}

.dropbtnn {
  background-color: white;
  color: black;
}

.dpprice .dropbtnn {
  background-color: white;
  height: 30px;
  text-decoration: none;
  font-size: 24px;
  border: none;
}

.filter-label {
  font-size: 1.2em;
  margin-right: 20px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  font-size: 1em;
}

.dropbtnn:hover,
.dropbtn:focus {
  background-color: #ddd;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: left;
}

.dropdown-content button:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.pagination button {
  background-color: #ddd;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 1.5em;
}

.pagination button:hover {
  background-color: #ccc;
}

.pagination button:disabled {
  cursor: not-allowed;
  background-color: #f1f1f1;
}

.page-indicator {
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  margin: 0 10px;
}

.shop-description {
  padding: 20px;
  background-color: #f9f9f9;
  margin-top: 20px; /* Space above this section */
  border-radius: 8px; /* Rounded corners */
}

.shop-description h2 {
  color: rgba(0, 0, 0, 0.742); /* Header color */
  text-align: left; /* Align header to the left */
  margin-bottom: 10px;
}

.shop-description h3 {
  color: rgba(0, 0, 0, 0.742); /* Header color */
  text-align: left; /* Align header to the left */
  margin-bottom: 10px;
}

.shop-description p, .shop-description ul {
  font-size: 16px;
  margin-bottom: 10px;
}

.shop-description ul {
  color: #878787; /* Paragraph color */
  text-align: left; /* Align paragraph to the left */
  line-height: 1.6; /* Optional: Improve readability */
  margin: 5px 0; /* Optional: Add margin between paragraphs */
}
/* Responsive Styles */
@media (max-width: 1024px) {
  .product {
    flex: 1 1 calc(33.33% - 20px);
  }

  .sales-tag-image {
    width: 60px;
    height: 60px;
  }

  .sales-tag-text {
    font-size: 1em;
  }
}

@media (max-width: 768px) {
  .product {
    flex: 1 1 calc(50% - 20px);
  }

  .sales-tag-image {
    width: 50px;
    height: 50px;
  }

  .sales-tag-text {
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .product {
    flex: 1 1 calc(100% - 20px);
  }

  .sales-tag-image {
    width: 40px;
    height: 40px;
  }

  .sales-tag-text {
    font-size: 0.8em;
  }
}
