/* Main Container */
.shipping-policy-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: -1px 2px 8px 10px rgb(181, 173, 207);
  font-family: 'Poppins', sans-serif;
  font-size: 1.2rem;
}

/* Heading */
.policy-heading {
  color: rgb(61, 57, 57);
  font-size: 3rem;
  font-family: 'Playfair Display', serif;
  font-variant: small-caps;
  text-align: center;
  margin-bottom: 20px;
}

/* Subheading */
.policy-subheading {
  font-size: 1.5rem;
  color: black;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
}

/* Text Content */
.policy-text {
  font-size: 1.5rem;
  color: black;
  line-height: 1.6;
  font-family: 'Poppins', sans-serif;
  text-align: start;
}

/* List Styling */
.policy-list {
  padding-left: 20px;
  margin-top: -10px;
}

.policy-list li {
  font-size: 1.5rem;
  color: black;
  margin-bottom: 8px;
  font-family: 'Poppins', sans-serif;
  text-align: start;
  list-style-type: disc;
}
