@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

/* Banner section */
.section1 {
    width: 100%;
    height: 90vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}

.img-slider {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    transition: transform 2s ease-in-out;
}

.img-slider img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 100%;
    opacity: 0;
    transition: opacity 2s ease-in-out, left 2s ease-in-out;
}

.img-slider img.active {
    left: 0;
    opacity: 1;
}

.backpack-image {
    position: absolute;
    right: 13%;
    bottom: 12%;
    width: 350px;
    height: auto;
    z-index: 2;
    animation: jump 3s infinite ease-in-out;
}

@keyframes jump {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
}

.navigation-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.dot {
    width: 10px;
    height: 10px;
    background-color: #ccc;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
}

.dot.active {
    background-color: #333;
}

body {
    font-family: 'Poppins', sans-serif;
}

h1 {
    text-align: center;
}

.product-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 20px;
}

.product {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 200px;
    padding: 15px;
    margin: 10px;
    text-align: center;
}

.product-image {
    width: 100%;
    height: auto;
    border-radius: 5px;
}

.product-title {
    font-size: 1.1rem;
    margin: 10px 0;
}

.product-description {
    font-size: 0.9rem;
    margin-bottom: 10px;
}

/* Non-selectable text styles */
.non-selectable-text-container {
    user-select: none;
    color: #878787;
    margin: 30px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
}
.non-selectable-text-container h2, .non-selectable-text-container h3 {
    font-weight: 500;
    font-size: 20px;
    color: #333;
}
.non-selectable-text-container p, .non-selectable-text-container ul {
    font-weight: 300;
    line-height: 1.6;
    font-size: 15px;
}
.non-selectable-text-container ul {
    list-style-type: disc;
    padding-left: 40px;
}
/* Prevent text selection and copying */
.non-selectable-text-container, 
.non-selectable-text-container * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.shop-now-button-container {
    text-align: center;
    margin-top: 20px;
    padding-bottom: 15px;
  }
  
  .shop-now-button {
    background-color: #ffd000;
    color: rgb(16, 16, 16);
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .shop-now-button:hover {
    background-color: #ffee00ec;
  }
/* Responsive Styles */
@media (max-width: 500px) {
    .product-container {
        justify-content: center;
    }

    .product {
        flex: 0 0 45%;
        max-width: 45%;
        margin: 5px;
    }

    /* Hide the PC banner on phone mode */
    .section1 {
        display: none;
    }

    /* Phone banner visibility */
    .phone-banner {
        display: block;
        width: 100%;
        margin: 10px 0;
    }

    .phone-banner img {
        width: 100%;
        height: auto;
    }
}

@media (min-width: 501px) {
    /* Hide the phone banner on PC */
    .phone-banner {
        display: none;
    }
}
