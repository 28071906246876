body {
    font-family: Arial, sans-serif;
    background-color: #ede5e5;
    margin: 0;
    padding: 0;
}

.container {
    width: 80%;
    margin: auto;
    overflow: hidden;
}

.checkout-container {
    max-width: 60%;
    margin: auto;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    margin-bottom: 20px;
}

.checkout-header {
    text-align: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.checkout-header h1 {
    margin: 0;
}

.checkout-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.delivery-address, .payment-method, .order-summary {
    width: 100%;
}

.delivery-address h2, .order-summary h2 {
    font-size: 1.3rem;
    font-family: Poppins;
}

input {
    width: 70%;
    display: block;
    border: none;
    border-bottom: 1px solid #999;
    padding: 6px 30px;
    font-family: Poppins;
    box-sizing: border-box;
    font-size: 1.1rem;
    background-color: #dddddd;
    margin-top: 2%;
}

.payment-method h2 {
    font-size: 1.5rem;
    font-family: 'Poppins', sans-serif;
    color: #333;
    margin-bottom: 10px;
}
  
.payment-options {
    display: flex;
    flex-direction: column;
}
  
.custom-radio {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 40px; /* Increased padding for more space */
    margin-bottom: 20px; /* More space between radio options */
    cursor: pointer;
    font-size: 1.1rem;
    font-family: 'Poppins', sans-serif;
    color: #333;
}
  
.custom-radio input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
  
.radio-mark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #f1f1f1;
    border-radius: 50%;
    border: 2px solid #ccc;
}
  
.custom-radio input:checked ~ .radio-mark {
    background-color: #ff6600;
    border-color: #ff6600; /* Ensure border changes with background */
}
  
.radio-mark:after {
    content: "";
    position: absolute;
    display: none;
}
  
.custom-radio input:checked ~ .radio-mark:after {
    display: block;
}
  
.custom-radio .radio-mark:after {
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: white;
}
  
.save-message {
    color: #ff6600; /* General save message color */
    font-size: 0.9rem;
    margin-left: 30px;
    font-family: 'Poppins', sans-serif;
}

.save-message.error {
    color: red; /* Red for error messages */
}
  
.save {
    color: #ff6600;
    font-size: 1rem;
    margin-left: 5px;
    font-weight: bold;
}

h2 {
    font-size: 1.9rem;
    margin-bottom: 10px;
}

p {
    margin: 5px 0;
}

.available-balance {
    margin-bottom: 20px;
}

#code {
    padding: 5px;
    margin-right: 10px;
}

#apply-code {
    padding: 5px 5px;
    background-color: #f0c14b;
    border: 1px solid #a88734;
    cursor: pointer;
}

.card-logos img {
    width: 50px;
    margin-right: 10px;
}

.order-summary h3 {
    font-size: 20px;
    color: #b12704;
}

.order {
    background-color: #ffd814;
    border: 1px solid #121211;
    padding: 15px;
    cursor: pointer;
    width: 50%;
    text-align: center;
    margin: 20px auto;
    font-size: 1rem;
    margin: 20px auto 0; /* Center the button */
    display: block; /* Center the button */
}

.order:hover{
    background-color: #f4e21c;
}

.orderDetail {
    margin-left: 10%;
}

.orderDetail p {
    font-size: 1.1rem;
    font-family: Poppins;
}

.order-summary {
    overflow-x: auto; /* Allows horizontal scrolling on smaller screens */
}

.order-summary table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.order-summary table, .order-summary th, .order-summary td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.order-summary th {
    background-color: #f2f2f2;
}

.total {
    font-size: 1.2rem;
    font-weight: bold;
}

.order-summary-box {
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
}

.checkout-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.delete-button {
    background-color: #ff4d4d;
    border: none;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
    font-size: 0.9rem;
}

.delete-button:hover {
    background-color: #ff1a1a;
}

.address-box {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}

.address-box.selected {
    border-color: #007bff;
    background-color: #e7f0ff;
}

.new-address-form {
    max-width: 700px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.new-address-form h3 {
    text-align: center;
    color: #333;
    font-size: 1.8em;
    margin-bottom: 20px;
}

.new-address-form div {
    margin-bottom: 16px;
}

.new-address-form label {
    display: block;
    font-size: 0.9em;
    font-weight: 500;
    color: #555;
    margin-bottom: 6px;
}

.new-address-form button {
    width: calc(50% - 10px);
    padding: 10px;
    font-size: 1em;
    color: #fff;
    background-color: #070F2B;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 10px 5px;
    box-sizing: border-box;
}

.add-address-label{
    font-size: 1.5em;
    margin: 10px 0;
    font-weight: bold;
    color: #333;
}
.new-address-form input[type="text"] {
    width: 100%;
    padding: 10px;
    font-size: 0.8em;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    transition: border-color 0.3s;
}

.new-address-form input[type="text"]:focus {
    border-color: #6c63ff;
    outline: none;
}

@media (max-width: 768px) {
    .new-address-form label {
        font-size: 0.6em;
        font-weight: 300;
    }
    .new-address-form button {
        width: calc(100% - 10px);
        margin: 10px 0;
        font-size: 0.9em;
    }
    .add-address-label{
        font-size: 1em;
    }
    .new-address-form input[type="text"] {
        font-size: 0.8em;
    }
}


.error {
    color: red;
    margin-top: 10px;
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px; /* Adjust the width to make it square */
    height: 300px; /* Matching height for a square shape */
    background: #070F2B;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.popup-content {
    text-align: center;
}

.popup-content p {
    font-size: 1.2rem;
    font-family: Poppins, sans-serif;
    color: #ccc;
}

.popup p{
    margin-top: 150px;
    color: white;
}

.close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
  } 

.orderBtn{
    padding: 20px;
    background-color: #FF9800;
    color: black;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    margin: 20px auto 0;
    display: block; 
    font-size: 1rem;
    width: 30%;
    transition: background-color 0.3s ease, transform 0.1s ease;
}

.orderBtn:hover,
.orderBtn:focus{
    background-color: #FFC107; 
}
.order-summary-pc {
    display: block;
  }
  
  .order-summary-mobile {
    display: none;
  }
.save-message{
    color: red;
}

.save{
    color: rgb(47, 159, 47);
}
.buttonAddAdress{

    padding: 10px;
    background-color: #070F2B;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}
.buttonAddAdress:hover{
    background-color:  #4f347e;
}
.quantity-controls {
    display: flex;
    padding-top: 20px;
    padding-left:15px ;
    align-items: center;
    gap: 8px;
  }
  
  .quantity-button {
    background-color: #4a71f1;
    border: 1px solid #ccc;
    color: #ccc;
    padding: 5px;
    cursor: pointer;
  }
  
  .quantity-button:hover {
    background-color: #072070;
  }
@media (max-width: 768px) {
    .checkout-container {
        max-width: 90%;
    }

    .checkout-content {
        gap: 8px;
    }

    input {
        width: 90%;
        padding: 6px 20px;
    }

    .order {
        width: 70%;
        font-size: 0.9rem;
    }

    .checkout-header h1 {
        font-size: 1.5rem;
    }

    /* Updated Order Summary section for mobile view */
    .order-summary-pc {
        display: none;
      }
    
      .order-summary-mobile {
        display: block;
      }
    .cart-items {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .cart-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        background-color: #f9f9f9;
    }

    .cart-item .checkout-image {
        width: 80px;
        height: 120px;
        margin-right: 8px;
    }

    .item-details {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .item-details h4 {
        font-size: 14px;
        margin: 0;
    }

    .item-details p {
        font-size: 12px;
        margin: 2px 0;
    }

    .delete-button {
        background-color: #ff4d4d;
        border: none;
        color: white;
        padding: 5px;
        cursor: pointer;
        border-radius: 3px;
    }

    .delete-button:hover {
        background-color: #ff1a1a;
    }

    .total {
        font-size: 16px;
        font-weight: bold;
        margin-top: 10px;
    }

    /* Hide table layout for Order Summary in mobile view */
    .order-summary table {
        display: none;
    }

    /* Address box styling */
    .address-box {
        padding: 8px;
    }

    /* Popup styling */
    .popup {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 250px; /* Adjust the width to make it square */
        height: 200px; /* Matching height for a square shape */
        background: #070F2B;
        padding: 20px;
        z-index: 1000;
    }
    
    .popup-content {
        text-align: center;
    }
    
    .popup-content p {
        font-family: Poppins, sans-serif;
        color: #ccc;
    }
    
    .popup p{
        margin-top: 50px;
        font-size: 1.5rem;
        color: white;
    }
    
    .close-button {
        position: absolute;
        top: 5px;
        right: 5px;
        background: transparent;
        border: none;
        font-size: 20px;
        cursor: pointer;
    } 
     .buttonAddAdress{

        padding: 10px;
        background-color: #070F2B;
        color: #fff;
        border: none;
        cursor: pointer;
        border-radius: 4px;
    }
    .buttonAddAdress:hover{
        background-color:  #4f347e;
    }
    .quantity-controls {
        display: flex;
        padding-top: 10px;
        padding-left:95px ;
        align-items: center;
        gap: 8px;
      }
      
      .quantity-button {
        background-color: #070F2B;
        border: 1px solid #ccc;
        padding: 5px;
        cursor: pointer;
      }
      
      .quantity-button:hover {
        background-color: #4f347e;
      }
}

input[type="radio"]:disabled {
    cursor: not-allowed;
  }