.register-container {
  width: 100%; /* Keep full width for responsiveness */
  max-width: 700px; /* Maintain the existing width on larger screens */
  background: #ede5e5;
  margin: 0 auto;
  box-shadow: -1px 2px 8px 10px #544;
  border-radius: 20px;
  margin-top: -100px;
  display: flex;
  flex-direction: column; /* Stack items on smaller screens */
  justify-content: center;
  padding: 20px; /* Keep padding for spacing */
}

.form-title {
  font-size: 2.5rem;
  color: #080808;
  text-align: center; /* Center the title */
}

.form-group {
  position: relative;
  margin-bottom: 15px;
}

.input1 {
  width: calc(100% - 100px); /* Adjust width considering padding */
  padding: 10px 20px; /* Maintain padding */
  border-radius: 25px;
  font-size: 14px;
  background: #f5f5f5;
  border: none;
  color: #222;
  font-family: Poppins;
  margin: 0 auto; /* Center input field */
}

.form-group-Policy p1 {
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 15px;
  color: #777;
}

.icon {
  position: absolute;
  color: #222;
  font-size: 1.2em; /* Base font size for larger screens */
  transition: font-size 0.3s ease; /* Smooth transition for resizing */
}
.icon-whatsapp {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  color: #53c249;
  font-size: 1.2em;
  transition: font-size 0.3s ease;
}


.signup-image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto; /* Center image */
  width: 100%;
}

.iconSignup img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.link-singup {
  color: #000;
  text-decoration: none;
  margin-top: 20px;
  margin-right: 30px;
  font-size: 1.2rem;
  text-align: center;
}

.link-singup u:hover {
  color: #555;
}

.form-submit1:hover,
.send-otp-button:hover,
.resend-otp-button:hover {
  cursor: pointer;
  background-color: white;
  color: black;
  border: 1px solid black;
}
.form-submit1:disabled{
   background-color: #ccc; /* Greyed out background */
  color: #666; /* Muted text color */
  cursor: not-allowed; /* Not-allowed cursor */
  opacity: 0.6; /* Slight transparency */
}

.resend-otp-button:disabled{
  cursor: not-allowed;
}

.error-input {
  border: 1px solid red;
}

.error-message {
  color: red;
  margin-top: 15px;
  margin-left: 40px;
}

.form-submit1,
.send-otp-button {
  width: 60%;
  border-radius: 25px;
  padding: 15px 20px; /* Increased padding for the button */
  background: #111;
  color: white;
  font-size: 16px;
  transition: 0.4s;
  margin: 20px auto;
}
.resend-otp-button {
  width: 60%;
  border-radius: 25px;
  padding: 15px 20px; /* Increased padding for the button */
  background: #26284d;
  color: white;
  font-size: 16px;
  transition: 0.4s;
  margin: 20px auto;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .register-container {
    padding: 15px; /* Adjust padding for smaller screens */
    margin-top: -250px;
  }

  .input1 {
    width: calc(100% - 60px); /* Reduce width for better fit */
    padding: 10px 15px; /* Adjust padding */
  }

  .form-title {
    font-size: 2rem; /* Adjust title size */
  }

  .signup-image {
    margin: 15px auto; /* Adjust image margin */
  }

  .link-singup {
    margin-right: 0; /* Remove extra margin */
    font-size: 1rem; /* Adjust font size */
  }

  .icon {
    font-size: 1em; /* Reduce icon size for smaller screens */
  }
  .form-submit1 {
    width: 60%;
  }
  .form-group-Policy p1 {
    font-size: 15px; 
  }
  .icon-whatsapp{
    left: 5px;
  }

}

@media (max-width: 480px) {
  .register-container {
    padding: 15px; /* Further adjust padding for very small screens */
  }

  .form-title {
    font-size: 1.8rem; /* Further reduce title size */
  }

  .input1 {
    width: calc(100% - 70px); /* Further adjust width */
    padding: 8px 10px; /* Adjust padding */
  }

  .link-singup {
    font-size: 0.9rem; /* Further adjust link font size */
  }

  .icon {
    font-size: 0.8em; /* Further reduce icon size for very small screens */
  }
  .form-submit1{
    width: 50%;
  }
  .icon-whatsapp{
    left: 5px;
  }
}
