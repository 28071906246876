.product-details-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-details-container {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 50px; /* Margin for separation from reviews section */
  justify-content: space-between; /* Adjust spacing between elements */
}

.image-gallery {
  display: flex;
  flex: 1;
  max-width: 100%;
  align-items: flex-start; /* Align thumbnails and main image at the top */
  margin-bottom: 20px; /* Space below image on small screens */
}

.thumbnail-container {
  display: flex;
  flex-direction: column;
  margin-right: 20px; /* Space between thumbnails and main image */
}

.thumbnail {
  width: 110px;
  height: 80px;
  margin-bottom: 20px; /* Space between thumbnails */
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  border-radius: 5px;
  border: 2px solid transparent;
}

.thumbnail:hover {
  transform: scale(1.1);
  border: 2px solid #000;
}

.main-image-container {
  position: relative; /* Needed for positioning the sales tag */
  width: 200%; /* Full width on mobile */
  display: flex;
  justify-content: center;
}

.sales-tag {
  position: absolute;
  top: 10px;
  right: 10px;
}

.sales-tag-image {
  width: 75px;
  height: 75px;
}

.sales-tag-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: bold;
  font-size: 1.2em;
  text-align: center;
  line-height: 1;
}

.main-image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
   margin-bottom: 10px; /* Space below the main image */
}

.product-info {
  flex: 1;
  max-width: 100%;
  padding: 10px;
  min-width: 200px;
  margin-left: 0px;
  text-align: center;
}

.product-info h1 {
  font-size: 30px;
  font-weight: bold;
  color: #0a0a0a;
  margin-top: 30px;
  text-align: center;
}

.product-info .separator {
  margin: 10px 0;
  border: 0;
  height: 1px;
  background: #ddd; /* Light gray for the separator line */
}

.product-info .name {
  font-size: 30px;
  font-weight: bold;
  color: #080808e6; /* Slightly faded black for the name */
  margin-top: -40px;
}

.product-info .shortDescription {
  font-size: 20px;
  color: #1d1d1d; /* Light gray for the short description */
  margin-top: 20px;
  margin-bottom: 25px;
}

.product-price {
  font-size: 20px;
  color: #777; /* Faded black for listing price */
  text-decoration: line-through;
  margin-bottom: 5px;
}

.product-selling-price {
  font-size: 30px;
  color: #dc1a00e1; /* Orange-red for selling price */
  font-weight: bold;
  margin-bottom: 15px;
}

.product-info .longDescription {
  font-size: 16px;
  color: #161616; /* Charcoal gray for the long description */
  margin-top: 20px;
  line-height: 1.5;
}
.toggle-image-view-btn{
  background-color: #007BFF; /* A professional blue color */
  color: white; /* White text for good contrast */
  padding: 5px 10px; /* Sufficient padding for a modern button look */
  border: none; /* Removing the border */
  border-radius: 15px; /* Smooth rounded edges */
  cursor: pointer;
  font-size: 16px; /* Font size for readability */
  font-weight: bold; /* Bold text to make it stand out */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions for hover and active states */
  margin-top: 5px; /* Space above the button */
}

.toggle-image-view-btn:hover {
  background-color: #0056b3; /* Darker blue on hover for effect */
  transform: translateY(-2px); /* Slight lift on hover for interaction feedback */
}

.toggle-image-view-btn:active {
  transform: scale(0.98); /* Scale down slightly when clicked */
  background-color: #004080; /* Even darker blue when clicked */
}
/* Style for the Read More and Show Less button */
.toggle-description-btn {
  background-color: #007BFF; /* A professional blue color */
  color: white; /* White text for good contrast */
  padding: 10px 20px; /* Sufficient padding for a modern button look */
  border: none; /* Removing the border */
  border-radius: 5px; /* Smooth rounded edges */
  cursor: pointer;
  font-size: 16px; /* Font size for readability */
  font-weight: bold; /* Bold text to make it stand out */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions for hover and active states */
  margin-top: 15px; /* Space above the button */
}

.toggle-description-btn:hover {
  background-color: #0056b3; /* Darker blue on hover for effect */
  transform: translateY(-2px); /* Slight lift on hover for interaction feedback */
}

.toggle-description-btn:active {
  transform: scale(0.98); /* Scale down slightly when clicked */
  background-color: #004080; /* Even darker blue when clicked */
}

.about-product,
.product-description,
.product-details{
  margin: 15px 0;
}
.percentage-difference {
  font-size: 0.9em;
  color: #4caf50;
}

.color-options {
  margin-top: 20px;
  
}

.color-options-buttons {
  display: flex;
}

.color-options-buttons button {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border: none;
  cursor: pointer;
  border-radius: 50%;
}

.color-options-buttons button.active {
  border: 2px solid #000;
}

.add-to-cart-btn {
  background-color: #FFD700; /* Yellow for "Add to Cart" */
  color: rgb(20, 18, 18);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  margin-right: 10px; /* Add space between the two buttons */
}

.buy-now-btn {
  background-color: #FF9800; /* Orange for "Buy Now" */
  color: rgb(17, 14, 14);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.add-to-cart-btn:hover {
  background-color: #ccad00; /* Darker yellow on hover */
}

.buy-now-btn:hover {
  background-color: #d37f00; /* Darker orange on hover */
}

.buy-now-btn:active {
  transform: scale(0.75); /* Scale down slightly when clicked */
}

.share-icon {
  font-size: 24px; /* Size of the share icon */
  color: black; /* Black color for the icon */
  cursor: pointer;
  margin-left: 30px; /* Increases space to the left of the share icon */
  margin-right: 10px; /* Optional: Adds space to the right of the share icon */
  margin-top: 0px; /* Adjust vertical alignment if needed */
  margin-bottom: -5px; /* Adjust vertical alignment if needed */
}

.share-btn:active {
  transform: scale(0.75);
}

.other-colors {
  margin-top: 20px;
}

.colors-container {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  text-align: center;
}

.color-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.color-item p {
  margin-bottom: 5px;
  font-weight: bold;
}

.color-thumbnail {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid transparent;
}

.color-thumbnail:hover {
  border: 2px solid rgb(97 59 96); /* Color on hover */
}

.reviews-section {
  width: 50%; /* Adjusted width for consistency */
  padding: 20px;
  background-color: #f9f9f9;
  border-top: 1px solid #ddd;
  max-width: 1200px;
}

.review-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.review-form label {
  margin-bottom: 10px;
}

.review-form textarea {
  resize: vertical;
  min-height: 60px;
}

.review {
  border-top: 1px solid #ddd;
  padding-top: 10px;
  margin-top: 10px;
}

.review-rating .star {
  color: #ddd;
}

.review-rating .star.filled {
  color: #f39c12; /* Gold for filled stars */
}

@media (max-width: 768px) {
  .product-details-container {
    display: flex;
    flex-wrap: wrap;
    width: 95%;
    max-width: 1200px;
    flex-direction: column;
    padding: 10px;
    align-items: center;
  }

  .image-gallery {
    display: flex;
    flex: 1;
    max-width: 100%;
    flex-direction: column; /* Stack main image and thumbnails in column */
    align-items: center;
    margin-bottom: 20px;
  }

  .thumbnail-container {
    display: flex;
    flex-direction: row; /* Row layout for thumbnails */
    flex-wrap: wrap; /* Allow wrapping if necessary */
    justify-content: center; /* Center thumbnails under the main image */
    margin-right: 0; /* Remove the margin between thumbnails and the main image */
    margin-top: 10px; /* Space above thumbnails */
    order: 2;
  }

  .thumbnail {
    width: 70px;
    height: 50px;
    margin: 5px; /* Space between thumbnails */
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    border-radius: 5px;
    border: 2px solid transparent;
  }

  .thumbnail:hover {
    transform: scale(1.1);
    border: 2px solid #000;
  }

  .main-image-container {
    order: 1;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .main-image {
    width: 90%; /* Adjust the size of the main image */
    height: auto;
  }
  .sales-tag {
    position: absolute;
    top: 5px;
    right: 10px;
  }
  
  .sales-tag-image {
    width: 55px;
    height: 55px;
  }
  
  .sales-tag-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bold;
    font-size: 1em;
    text-align: center;
    line-height: 1;
  }

  .product-info {
    width: 100%;
    padding: 0;
    text-align: center;
  }

  .product-info h1 {
    font-size: 24px;
    margin-top: 15px;
    text-align: center
  }

  .product-description, .product-price, .product-selling-price {
    font-size: 20px;
  }

  .percentage-difference {
    font-size: 0.85em;
  }

  .add-to-cart-btn {
    background-color: #FFD700; /* Yellow for "Add to Cart" */
    color: rgb(20, 18, 18);
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    margin-right: 10px; /* Add space between the two buttons */
  }
  
  .buy-now-btn {
    background-color: #FF9800; /* Orange for "Buy Now" */
    color: rgb(17, 14, 14);
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }

  .share-icon {
    font-size: 20px;
    margin-top: 10px;
  }

  .colors-container {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    text-align: center;
  }

  .color-thumbnail {
    width: 50px;
    height: 50px;
  }

  .other-colors h2 {
    font-size: 20px;
    margin-top: 15px;
  }
  .toggle-description-btn {
    font-size: 14px; /* Smaller font for mobile */
    padding: 8px 12px; /* Adjust padding for mobile view */
  }
  .toggle-image-view-btn {
    font-size: 14px; /* Smaller font for mobile */
    padding: 5px 8px; /* Adjust padding for mobile view */
  }
  
  .indicator-dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.3s ease;
}
.active-dot {
  background-color: #333;
}
}