/* General Styles */
.dynamic-form-container {
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .section-title {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #333;
  }
  
  .divider {
    margin: 20px 0;
    border: none;
    height: 1px;
    background-color: #ccc;
  }
  
  /* Form Styles */
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-label {
    display: block;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .form-input,
  .form-select,
  .form-file-input {
    width: 100%;
    padding: 8px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .form-input:focus,
  .form-select:focus,
  .form-file-input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .form-checkbox {
    margin-right: 10px;
  }
  
  .checkbox-label {
    font-size: 1rem;
    display: flex;
    align-items: center;
  }
  
  /* Variable Labels */
  .variable-labels {
    margin-top: 10px;
  }
  
  .variable-input-group {
    margin-bottom: 10px;
  }
  
  .add-variable-button {
    margin-top: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px 12px;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-variable-button:hover {
    background-color: #0056b3;
  }
  
  /* Buttons */
  .submit-button-marketing {
    background-color: #28a745;
    color: white;
    font-size: 1rem;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button-marketing:hover {
    background-color: #218838;
  }
  