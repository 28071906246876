/* Base Styles */
.main {
  background: #d2ced9;
  padding: 50px 0;
}

.login-container {
  width: 100%;
  max-width: 800px;
  padding: 30px;
  background: #ede5e5;
  margin: 0 auto;
  box-shadow: -1px 2px 8px 10px #544;
  border-radius: 20px;
  display: flex;
  align-items: center;
  margin-top: -150px;
  flex-wrap: wrap;
  overflow: hidden;
}

.signin-content {
  padding: 50px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.signin-form {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 50px;
}

.signin-image {
  width: 100%;
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
}

.form-title1 {
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  color: #080808;
  margin-top: -20px;
  font-size: 2.3rem;
  
}


.signin-image figure {
  margin-bottom: -40px; /* Increased space between logo and username */
  text-align: center;
}

.signin-image img {
  max-width: 100%;
  height: auto;
  width: 70%;
} 

.form-group {
  margin-bottom: 30px; /* Increased space between inputs */
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.input2 {
  width: 100%;
  padding: 10px 100px;
  border-radius: 25px;
  font-size: 16px;
  background: #f5f5f5;
  border: none;
  color: #222;
  font-family: Poppins, sans-serif;
}

.icon {
  position: absolute;
  left: 15px;
  color: #222;
  font-size: 1.2em;
}

.form-submit,
.send-otp-button {
  width: 60%;
  border-radius: 25px;
  padding: 15px 20px; /* Increased padding for the button */
  background: #111;
  color: white;
  font-size: 16px;
  transition: 0.4s;
  margin: 20px auto;
  cursor: pointer;
}
.resend-otp-button {
  width: 60%;
  border-radius: 25px;
  padding: 15px 20px; /* Increased padding for the button */
  background: #26284d;
  color: white;
  font-size: 16px;
  transition: 0.4s;
  margin: 20px auto;
}

.form-submit:hover,
.send-otp-button:hover,
.resend-otp-button:hover  {
  background-color: white;
  color: black;
  border: 1px solid black;
}
.form-submit:disabled{
  background-color: #ccc; /* Greyed out background */
  color: #666; /* Muted text color */
  cursor: not-allowed; /* Not-allowed cursor */
  opacity: 0.6; /* Slight transparency */
}
.resend-otp-button:disabled{
  cursor: not-allowed;
}
@media (max-width: 768px) {
  .form-group.form-button {
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Center align buttons */
  }

  .form-submit,
  .send-otp-button {
    width: 80%; /* Full width for smaller screens */
    margin:0; /* Add spacing between buttons */
  }
}


.forget {
  text-align: center;
  margin-top: 30px; /* Added padding for "Forget Password" */
  padding-top: 20px; /* Additional padding */
}

.error-message {
  color: red;
  margin-top: 15px;
}

.signup-image-link {
  font-size: 1.6rem;
  text-align: center;
  display: block;
  font-weight: 600;
  color: #000;
  text-decoration: none;
  margin-top: 30px;
}

.signup-image-link:hover {
  color: #555;
}

.login-icon {
  color: black;
  font-size: 70px;
  animation: bounce 2s infinite;
}

/* Social login section */
.social-login {
  margin-top: 20px;
  text-align: center;
}

.social-label {
  display: block;
  margin-bottom: 10px;
}

.socials {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center; /* Center social icons */
}

.socials li {
  margin: 0 10px;
}

.google-icon {
  color: #DB4437;
}

.facebook-icon {
  color: #4267B2;
}

.twitter-icon {
  color: #1DA1F2;
}

.social-logo {
  width: 40px;
  height: 40px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .login-container {
    width: 95%;
    padding: 10px;
    box-shadow: -1px 2px 8px 10px #544;
  border-radius: 20px;
  margin-top: -100px;
  }

  .signin-form, .signin-image {
    width: 100%;
    padding: 20px 0;
    align-items: center;
    margin-right: 30px;
    margin-left: 20px;
  }

  .signin-image img {
    width: 60%;
    margin-bottom: 20px;
  }

  .form-submit {
    width: 80%;
  }

  .signup-image-link {
    font-size: 14px;
    margin-top: 20px;
  }

  .socials {
    justify-content: center;
  }
  .forget {
    font-size: 18px;
    margin-top: 15px; /* Added padding for "Forget Password" */
    padding-top: 10px; /* Additional padding */
  }
}

@media (max-width: 480px) {
  .signin-image img {
    width: 50%;
    margin-bottom: 10px;
  }

  .form-submit {
    width: 100%;
  }

  .signup-image-link {
    font-size: 20px;
    margin-top: 10px;
  }

  .icon {
    font-size: 1em;
  }

  .input2 {
    padding: 8px 40px;
    font-size: 14px;
  }

  .social-logo {
    width: 30px;
    height: 30px;
  }

  .socials li {
    margin: 0 5px;
  }

  .forget {
    font-size: 18px;
    margin-top: 15px; /* Added padding for "Forget Password" */
    padding-top: 10px; /* Additional padding */
  }
}
.switch-method {
  cursor: pointer;
  color: #007bff; 
  text-decoration: underline; 
  font-weight: bold;
  transition: color 0.3s ease;
}

.switch-method:hover {
  color: #0056b3;
}