#Return{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px; /* Reduced margin between sections */
}

#ReturnMain{
    background: #d2ced9;
    padding: 50px 0;
}
.ReturnPolicy{

    /* margin: auto;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05); */
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: -1px 2px 8px 10px rgb(181, 173, 207);
    font-family: 'Poppins';
    font-size: 1.2rem;
    
}

.ReturnPolicy h1{
    color: rgb(61, 57, 57);
    font-size: 3rem;
    font-family: 'Playfair Display', serif;
    font-variant: small-caps;
    

}

.returncontent{

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /*padding: 40px;  Reduced padding */
    flex: 1;

}

.returncontent p{

    font-size: 1.5rem;
    text-align: start;
    margin-top: 10px;
    color: black;
    font-family: 'Poppins';
}

.returncontent ul{
    font-size: 1.5rem;
    font-weight: 400;
    margin-top: -10px;
    text-align: start;
    list-style-type: disc;
    padding-left: 20px;
    font-family: 'Poppins';
}