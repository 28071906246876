/* cart.css */
.cart-container {
  padding: 20px;
  width: 120%;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.cart-items-wrapper {
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.cart-items {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

.cart-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e9ecef;
  padding: 15px 0;
  transition: background-color 0.3s ease;
}

.cart-item:hover {
  background-color: #f1f1f1;
}

.cart-item-image {
  width: 80px; /* Adjust the image size to fit better */
  height: auto;
  margin-right: 10px;
  border-radius: 8px;
}

.cart-item-details {
  flex: 1;
}

.cart-item h2 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
  color: black;
}

.price-details {
  margin-bottom: 10px;
  font-size: 14px;
}

.listing-price {
  text-decoration: line-through;
  color: #6c757d;
  margin-right: 8px;
}

.discount-price {
  color: red;
  margin-right: 8px;
}

.selling-price {
  font-weight: bold;
  color: #28a745;
}

.cart-item-quantity {
  display: flex;
  align-items: center;
}

.cart-item-quantity button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 5px 10px;
  margin: 0 5px;
  transition: background-color 0.3s ease;
}

.cart-item-quantity button:hover {
  background-color: #0056b3;
}

.cart-item-quantity .delete-button {
  background-color: #dc3545;
}

.cart-item-quantity .delete-button:hover {
  background-color: #c82333;
}

.cart-item-quantity span {
  margin: 0 10px;
  font-size: 14px;
  color: black;
}

.total-price {
  font-weight: bold;
  margin-top: 10px;
  color: black;
}

.cart-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #e9ecef;
  background-color: #ffffff;
  border-radius: 8px;
}

.cart-total h2 {
  font-size: 18px;
  margin: 0;
  color: black;
}

.empty-cart-message {
  font-size: 16px;
  color: #6c757d;
  text-align: center;
}