/* Main container */
.main {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #ece9e6 0%, #ffffff 100%);
    padding: 40px;
}

/* Contact Us card */
.contact-us {
    max-width: 600px;
    width: 100%;
    padding: 40px;
    background: #ffffff;
    border-radius: 16px;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contact-us:hover {
    transform: translateY(-10px);
    box-shadow: 0 25px 50px rgba(0, 0, 0, 0.15);
}

/* Heading */
.contact-us h2 {
    text-align: center;
    margin-bottom: 30px;
    color: #333333;
    font-size: 2.2rem;
    font-weight: 700;
    letter-spacing: 1.5px;
}

/* Form styling */
.contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contact-form label {
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    color: #555555;
    font-weight: 600;
}

.contact-form input,
.contact-form textarea {
    padding: 14px 20px;
    font-size: 1rem;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    outline: none;
    background-color: #f9f9f9;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
    border-color: #007BFF;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
}

.contact-form textarea {
    resize: vertical;
    min-height: 120px;
}

/* Submit button */
.contactBtn {
    padding: 14px 30px;
    background-color: #070F2B;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
    align-self: center;
    box-shadow: 0 10px 20px rgba(0, 123, 255, 0.3);
}

.contactBtn:hover {
    transform: translateY(-3px);
    
}

/* Response message */
.response-message {
    margin-top: 20px;
    text-align: center;
    color: green;
    font-size: 1rem;
    font-weight: 600;
}
