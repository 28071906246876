#PrivacyMain {
    background: #d2ced9;
    padding: 50px 0;
}

.privacy-policy-container {
    width: 90%; /* Adjust width for smaller screens */
    max-width: 1200px; /* Set a maximum width */
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: -1px 2px 8px 10px rgb(181, 173, 207);
    font-family: 'Poppins', sans-serif; /* Added fallback font */
    font-size: 1.2rem;
}

.privacy-policy-container h3 {
    padding: 10px;
    background-color: #d8c7f1;
    color: rgb(10, 9, 9);
    border-radius: 4px;
}

.privacy-policy-header {
    font-size: 1.5rem; /* Adjusted for better readability */
    font-weight: bold;
    cursor: pointer;
    padding: 10px;
    background-color: #07157d;
    color: white;
    border-radius: 4px;
    text-align: center;
}

.headingTop p1 {
    font-size: 1.2rem;
    font-family: 'Poppins', sans-serif; /* Added fallback font */
}

.privacy-policy-content {
    overflow: hidden;
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    max-height: 0;
    padding: 0 10px;
    opacity: 0;
}

.privacy-policy-content.show {
    max-height: 1000px; /* Adjust based on your content size */
    opacity: 1;
    padding: 10px;
}

.privacy-policy-content.hide {
    max-height: 0;
    opacity: 0;
    padding: 0 10px;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .privacy-policy-container {
        width: 95%; /* Increase width for small screens */
        padding: 10px;
        font-size: 1rem; /* Slightly smaller font size */
    }

    .privacy-policy-header {
        font-size: 1.25rem; /* Smaller header font size */
        padding: 8px;
    }

    .privacy-policy-content.show, .privacy-policy-content.hide {
        padding: 0 5px; /* Reduced padding for smaller screens */
    }
}

@media (max-width: 480px) {
    .privacy-policy-container {
        width: 100%; /* Full width for very small screens */
        padding: 5px;
        font-size: 0.9rem; /* Further reduced font size */
    }

    .privacy-policy-header {
        font-size: 1rem; /* Smaller header font size */
        padding: 6px;
    }
}
