/* General page styles */

.return-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.return-popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.success {
  color: green;
  margin-bottom: 20px;
  font-size: 1.2em;
}

.error {
  color: red;
  margin-bottom: 20px;
  font-size: 1.2em;
}

.close-popup-btn {
  background-color: #FF6600;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.close-popup-btn:hover {
  background-color: #E65C00;
}



.return-order-container {
  max-width: 60%;
  margin: auto;
  background-color: #fff;
  padding: 20px;
  box-shadow: -1px 2px 8px 10px #544;
  margin-top: 30px;
}

.return-order-container h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
  margin-right: 50px;
}

.checkbox-item {
  margin: 15px 0;
  display: flex;
  align-items: center;
}

.checkbox-image-container {
  display: flex;
  align-items: center;
}

.item-image {
  max-width: 100px;
  margin-left: 10px; /* Adds gap between the checkbox and image */
}

.item-label {
  margin-left: 20px;
}

.checkbox{
  -webkit-appearance: checkbox !important;
  -moz-appearance: checkbox !important;
  appearance: checkbox !important;
  width: 16px !important;
  height: 16px !important;
}

.returnBtn {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #070F2B;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, filter 0.2s;
}

.returnBtn:hover {
  transform: scale(1.1);
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.7));
}

/* Responsive styles */
@media (max-width: 768px) {
  .return-order-container {
    padding: 15px;
    max-width: 90%;
  }

  .return-order-container h2 {
    font-size: 20px;
  }

  .checkbox-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .returnBtn {
    padding: 8px 16px;
    font-size: 16px;
  }
}
