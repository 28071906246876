body {
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
    background-color: #ede5e5;

}
.one{
    font-size: 1.4rem;
    display: block;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 14px;
    color: #333;
    width: 100%;
}
#changeAdd {
    max-width: 60%; /* Adjusted to fit vertical layout */
    margin: auto;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    color: black;
    margin-top: 30px;
}

.delivery-address-change {
    width: 100%;
}

.delivery-address-change h2 {
    text-align: center;
    font-size: 2.8rem;
    margin-left: -10px;
    margin-bottom: 10px;
    
}

.delivery-address-change .one {
    font-size: 1.2rem;
    font-family: Poppins;
}

.inputChangeAdd
{
    width: 80%;
    display: block;
    border: none;
    border-bottom: 1px solid #999;
    padding: 6px 10px;
    font-family: Poppins;
    box-sizing: border-box;
    font-size: 1.1rem;
    background-color: #dddddd;
    margin-top: 2%;
}

.btn2 {
    padding: 10px;
    font-size: 1rem;
    width: 100%;
    background-color: #070F2B;
    color: #fff;
    border: none;
    cursor: pointer;
    margin: 20px auto 0; /* Center the button */
    display: block; /* Center the button */
    border-radius: 4px;
}

.btn2:hover{
    background-color:  #4f347e;
}

@media (min-width: 600px) {
    input {
        width: 70%;
        padding: 6px 20px;
    }

    .btn2 {
        width: 50%; /* Center the button */
        padding: 15px 10px;
    }
}

@media (min-width: 768px) {
    #changeAdd {
        max-width: 50%;
    }

    .btn2 {
        width: 50%; /* Center the button */
    }
}
