.account-container {
  display: flex;
  background-color: #ddd;
}

/* Sidebar Menu */
.account-dropdown {
  width: 260px;
  border-right: 1px solid #ddd;
  background: #0d1533;
  margin-bottom: -1px;
}

.dropdown-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu-item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-menu-item a {
  text-decoration: none;
  color: #dfdbdb;
}

.dropdown-menu-item a:hover {
  text-decoration: underline;
}

/* Main Content Area */
.account-details {
  flex-grow: 1;
  padding: 20px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .account-container {
    flex-direction: column;
  }

  .account-dropdown {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #ddd;
  }

  .dropdown-menu-item {
    padding: 15px;
  }

  .account-details {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .dropdown-menu-item {
    padding: 12px;
    font-size: 14px;
  }

  .account-details {
    padding: 10px;
  }
}
