/* Reset body margin */
body {
    margin: 0;
}

/* Navbar styles */
.navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #070F2B; /* Primary Color: Charcoal Gray /new color navy blue*/
    padding: 0px 5px;
    color: #FFFFFF; /* Accent Color: White */
    position: sticky;
    top: 0;
    z-index: 1000;
    transition: background-color 0.3s ease;
}

/* Container for navbar content */
.navbar-container {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    justify-content: space-between;
    padding: 0 15px;
}

/* Mobile menu icon styles */
.mobile-menu-icon {
    display: none;
    font-size: 1.5rem;
    color: #FFFFFF; /* Accent Color: White */
    background-color: #FF6600; /* Secondary Color: Light Gray */
    border: none;
    cursor: pointer;
    margin-left: auto;
    transition: transform 0.3s ease;
}

/* Morph animation for mobile menu icon */
.mobile-menu-icon.open {
    transform: rotate(90deg);
    padding-left: 10px;
}

/* Navbar brand/logo styles */
.navbar-brand {
    display: flex;
    justify-content: center;
    margin: 0 auto;
}

/* Logo image styles with increased height */
.navbar-brand img {
    height: 60px; /* Increased logo height */
    margin: 0;
    max-width: 100%; /* Ensure the logo resizes proportionally */
}

/* Navbar navigation list styles */
.navbar-nav {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    transition: opacity 0.5s ease, transform 0.5s ease;
}

/* Navigation items styles */
.navbar-nav ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

/* Navigation list item styles */
.navbar-nav li {
    margin: 0 12px;
    position: relative;
}

/* Navigation links styles */
.navbar-nav a {
    color: #FFFFFF; /* Accent Color: White */
    text-decoration: none;
    padding: 8px 16px;
    transition: box-shadow 0.3s ease, color 0.3s ease;
    font-size: 1rem;
    font-weight: 500;
}

/* Navigation links hover effect */
.navbar-nav a:hover {
    box-shadow: 0 0 8px 2px #FFFFFF; /* Accent Color: White */
    border-radius: 4px;
    color: #D3D3D3; /* Text Color: Light Gray */
}

/* Cart icon container styles */
.cart-icon {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 20px;
    cursor: pointer;
}

/* React cart icon styles */
.react-cart-icon {
    font-size: 1.8rem;
    color: #FFFFFF; /* Accent Color: White */
    transition: transform 0.3s ease, color 0.3s ease;
    filter: drop-shadow(0px 0px 3px rgba(0,0,0,0.5)); /* Add shadow to enhance boldness */
}

/* Cart icon hover effect */
.cart-icon:hover .react-cart-icon {
    transform: scale(1.2); /* Increase size on hover */
    filter: drop-shadow(0px 0px 6px rgba(0,0,0,0.7)); /* Increase shadow on hover */
}

/* Style for the cart count badge */
.cart-icon span {
    position: absolute;
    top: -8px; /* Adjust as needed */
    right: -10px; /* Adjust as needed */
    background-color: red;
    color: #FFFFFF; /* Accent Color: White */
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 0.8rem;
    font-weight: bold;
    line-height: 1;
    min-width: 10px; /* Ensure the badge is big enough */
    height: 15px; /* Ensure the badge is round */
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Dropdown menu styles */
.dropdown {
    position: relative;
    display: inline-block;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Dropdown button styles */
.dropdown .dropbtn {
    background-color: #333333; /* Primary Color: Charcoal Gray */
    transition: transform 0.3s ease, background-color 0.3s ease;
}

/* Morph animation for dropdown button on hover */
.dropdown:hover .dropbtn {
    background-color: #FF6600; /* Secondary Color: Light Gray */
    transform: scale(1.05); /* Slightly scale up the button */
}

/* Dropdown content styles */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    left: 0;
    opacity: 0;
    transform: scale(0.9) translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Dropdown content links styles */
.dropdown-content a {
    color: #333333; /* Primary Color: Charcoal Gray */
    background-color: #FFFFFF; /* Accent Color: White */
    padding: 10px 12px;
    text-decoration: none;
    display: block;
    white-space: nowrap;
    transition: background-color 0.3s ease, color 0.3s ease;
}

/* Dropdown content hover effect */
.dropdown-content a:hover {
    background-color: #070F2B; /* Primary Color: Dark blue */
    color: #FFFFFF; /* Accent Color: White */
}

/* Show dropdown content on hover */
.dropdown:hover .dropdown-content {
    display: block;
    opacity: 1;
    transform: scale(1) translateY(0);
}

/* Social media icons container styles */
.social-icons {
    display: none;
    align-items: center;
    gap: 10px;
    margin-right: auto;
}

/* Social media icons styles */
.social-icons a {
    color: #FFFFFF; /* Accent Color: White */
    font-size: 1.4rem;
    transition: color 0.3s;
}

/* Social media icons hover effect */
.social-icons a:hover {
    color: #D3D3D3; /* Text Color: Light Gray */
}

/* Responsive styles for screens smaller than 1024px */
@media (max-width: 1024px) {
    .navbar-container {
        padding: 0 10px;
    }

    .navbar-nav li {
        margin: 0 8px;
    }

    .navbar-nav a {
        padding: 6px 12px;
        font-size: 0.9rem;
    }

    .cart-icon {
        margin-left: 15px;
    }
}

/* Responsive styles for screens smaller than 768px */
@media (max-width: 768px) {
    .navbar-container {
        flex-direction: row;
        justify-content: space-between;
        padding: 0 5px;
    }

    .navbar-brand {
        flex-grow: 1;
        text-align: center;
    }

    .navbar-nav {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: #070F2B; /* Primary Color: Charcoal Gray */
        flex-direction: column;
        align-items: center;
        display: none;
        opacity: 0;
        transform: scale(0.9) translateY(-10px);
        transition: opacity 0.3s ease, transform 0.3s ease;
    }

    /* Show mobile menu when active */
    .navbar-nav.active {
        display: flex;
        opacity: 1;
        transform: scale(1) translateY(0);
    }

    .navbar-nav ul {
        flex-direction: column;
        width: 100%;
        padding: 10px 0;
    }

    .navbar-nav li {
        margin: 10px 0;
    }

    .social-icons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin: 10px 0;
    }

    /* Show mobile menu icon */
    .mobile-menu-icon {
        display: block;
        font-size: 1.8rem;
        border-radius: 8px;
    }
}

/* Responsive styles for screens smaller than 576px */
@media (max-width: 576px) {
    .navbar {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .navbar-container {
        flex-direction: row;
        justify-content: space-between;
    }

    .navbar-brand {
        order: 2;
    }

    .cart-icon {
        order: 3;
    }

    .mobile-menu-icon {
        order: 1;
    }

    .navbar-nav {
        order: 4;
    }
}
